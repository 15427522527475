import React from "react";

import {
  createMuiTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core";

let theme = createMuiTheme({
  typography: {
    fontFamily: ["Abril Fatface"].join(","),
  },
  palette: {
    primary: {
      main: "#7D2133",
    },
  },
});
theme = responsiveFontSizes(theme);
theme.typography.h2 = {
  fontSize: "1.5rem",
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.5rem",
  },
};
theme.typography.h3 = {
  fontSize: "1.2rem",
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
};
theme.typography.h4 = {
  fontSize: "1.1rem",
  fontFamily: "Abril Fatface",
  fontWeight: 400,
  lineHeight: 1.334,
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "1.35rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.75rem",
  },
};
theme.typography.h5 = {
  fontSize: "1.05rem",
  fontFamily: "Abril Fatface",
  fontWeight: 400,
  lineHeight: 1.334,
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "1.25rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.55rem",
  },
};
theme.typography.h6 = {
  fontSize: "1.0rem",
  fontFamily: "Abril Fatface",
  fontWeight: 500,
  lineHeight: 1.6,
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: "1.15rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.35rem",
  },
};
const Theme = ({ path, children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
export default Theme;
