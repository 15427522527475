const React = require("react")
const {GoogleReCaptchaProvider} = require("react-google-recaptcha-v3")
const ThemeProvider = require('./src/components/theme-provider').default

exports.wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_CAPTCHA_CLIENT}>
            <ThemeProvider>
                {element}
            </ThemeProvider>
        </GoogleReCaptchaProvider>
    )
}